import {
  DrawerLayout,
  Genres,
  IndexCarouselItemType,
} from '@/components'
import {useIsLg} from '@/hooks'
import {GameContextProvider} from '@/hooks/game'
import Head from 'next/head'
import React from 'react'
import {CarouselItems, CarouselItemsContents, getCarouselItems} from '@/services/microcms/carousel-items'


const IndexPage = ({carouselItems}: {carouselItems: CarouselItemsContents[]}) => {
  const isLg = useIsLg()

  const calendarCssName = isLg ? 'react-calendar-pc.css' : 'react-calendar-sp.css'
  const parsedCarouselItems: IndexCarouselItemType[] = carouselItems.map(
    (item) => {
      return {
        image_url: item.image.url,
        link_url: item.link_url,
      }
    }
  )
  return (
    <GameContextProvider>
      <Head>
        <link rel="stylesheet" type="text/css" href={`/styles/${calendarCssName}`}/>
      </Head>
      <DrawerLayout carouselItems={parsedCarouselItems}>
        <Genres />
      </DrawerLayout>
    </GameContextProvider>
  )
}

export default IndexPage

export const getServerSideProps = async () => {
  try {
    const carouselItems: CarouselItems = await getCarouselItems()
    return {
      props: {
        carouselItems: carouselItems?.contents ?? [],
      },
    }
  } catch (e) {
    return {props: {carouselItems: []}}
  }
}
